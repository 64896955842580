import React from 'react';

import Icon from './Icon';

export default function SolidOpenAIIcon({
  className,
  text = 'OpenAI',
}: { className?: string; text?: string }): JSX.Element {
  return (
    <Icon text={text}>
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 44 44"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M43.55 22.73A10.75 10.75 0 0 0 40.89 18a10.62 10.62 0 0 0-.84-9A11.08 11.08 0 0 0 28 3.89a11 11 0 0 0-18.88 4 10.9 10.9 0 0 0-7.52 5.22A10.75 10.75 0 0 0 3.11 26a10.62 10.62 0 0 0 .84 9A11 11 0 0 0 16 40.1a11 11 0 0 0 18.89-3.95 10.9 10.9 0 0 0 7.52-5.22 10.66 10.66 0 0 0 1.14-8.2ZM35.36 33a.67.67 0 0 0-.37-.56V22.25a1 1 0 0 0-.5-1.09l-10-5.65 3.61-2.05 8.46 4.81a8.13 8.13 0 0 1 3.87 5 8 8 0 0 1-.83 6.16A8.19 8.19 0 0 1 35.36 33ZM8.64 11a.66.66 0 0 0 .38.56v10.19a.93.93 0 0 0 .49 1.09l10 5.65-3.61 2.05-8.5-4.82a8.13 8.13 0 0 1-3.87-5 8 8 0 0 1 .83-6.16A8.19 8.19 0 0 1 8.64 11Zm29.71 5.06a1 1 0 0 0-.69-.08l-8.9-5.06a1.26 1.26 0 0 0-1.24 0l-10.14 5.79v-4.2L26 7.7a8.39 8.39 0 0 1 11.36 3 8 8 0 0 1 .99 5.38v-.02ZM22 17l4.62 2.62v4.83L22 27l-4.61-2.62v-4.8L22 17Zm2.58-11.37-9.05 5.06a1 1 0 0 0-.53 1.09V23l-3.64-2v-9.4a1.18 1.18 0 0 0 0-.2A8.32 8.32 0 0 1 25 5.1a1 1 0 0 0-.42.53ZM5.65 27.92a1 1 0 0 0 .43.11 1 1 0 0 0 .26 0l8.9 5.06c.384.22.856.22 1.24 0l10.15-5.77v4.2L18 36.3a8.39 8.39 0 0 1-11.36-3 8 8 0 0 1-.99-5.38Zm13.77 10.45 9.06-5.05a1 1 0 0 0 .52-1.1V21l3.63 2v9.4a1.18 1.18 0 0 0 0 .2A8.32 8.32 0 0 1 19 38.9a1 1 0 0 0 .42-.53Z" />
      </svg>
    </Icon>
  );
}
