import React from 'react';

import Icon from './Icon';

export default function PocketLogoIcon({ className = '' }): JSX.Element {
  return (
    <Icon text="Pocket">
      <svg
        className={className}
        width="28"
        height="26"
        viewBox="0 0 28 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.9076 0.777344C1.44254 0.873763 0.555542 1.71837 0.555542 3.22061V12.2171C0.555542 19.5243 7.75211 25.2669 13.9775 25.2215C20.9776 25.1705 27.4444 19.2012 27.4444 12.2171V3.22061C27.4444 1.71271 26.5014 0.862366 25.025 0.777344H2.9076ZM8.57731 8.09587L13.9775 13.2772L19.3834 8.09587C21.8028 7.06981 22.8581 9.8589 21.8645 10.6752L14.8252 17.4608C14.5951 17.6819 13.3657 17.6819 13.1356 17.4608L6.09615 10.6752C5.14741 9.77381 6.3824 6.93939 8.57731 8.09587Z"
          fill="#EF4056"
        />
      </svg>
    </Icon>
  );
}
