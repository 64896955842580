import React from 'react';

import { Dialog } from './Dialog';

export const RemoveOpenAIApiKeyDialog = ({
  isOpen,
  onConfirm,
  onCancel,
}: {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  if (isOpen) {
    return (
      <Dialog
        id="remove-openai-api-key"
        title="Remove your OpenAI API Key?"
        subtitle="Removing your OpenAI API key will immediately stop auto-summarization of new documents."
        actionTitle="Remove"
        cancelTitle="Cancel"
        redActionButton
        action={onConfirm}
        cancelAction={onCancel}
      />
    );
  }

  return null;
};
