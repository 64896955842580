import React from 'react';

import Icon from './Icon';

export default function InstapaperLogoIcon({ className = '' }): JSX.Element {
  return (
    <Icon text="Instapaper">
      <svg
        className={className}
        width="14"
        height="28"
        viewBox="0 0 14 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.507172 27.0063V25.6047C0.901732 25.5784 4.50119 25.4456 4.50119 23.0263V5.03334C4.50119 2.55007 0.930959 2.42164 0.507172 2.39532V0.993652H13.4928V2.39532C13.0565 2.40799 9.50612 2.52699 9.52415 4.84703C9.54219 7.16707 9.50612 4.97014 9.50612 5.03334V23.0263C9.50612 25.4687 13.0106 25.5915 13.4928 25.6047V27.0063H0.507172Z"
          fill="white"
        />
      </svg>
    </Icon>
  );
}
