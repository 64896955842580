import React from 'react';

import Icon from './Icon';

export default function LogoIcon({ className = '' }): JSX.Element {
  return (
    <Icon text="Logo">
      <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_15772_6371)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0H24V24H0V0ZM0.97166 0.97166V23.0283H23.0283V0.97166H0.97166Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1498 18.3992C18.3056 18.2989 17.8929 18.0191 17.3868 17.1773L14.5163 12.5088C16.6546 12.1497 17.7156 11.1073 17.7156 8.92461C17.7156 6.04005 15.6775 5.21924 11.7763 5.21924H5.30521V6.06104C6.54344 6.16131 6.7883 6.30123 6.7883 7.46251V16.9977C6.7883 18.138 6.50613 18.2989 5.30521 18.3992V19.241H11.592V18.3992C10.3911 18.2989 10.1089 18.1403 10.1089 16.9977V12.7303H11.0837L14.985 19.241H19.1498V18.3992ZM14.4629 10.1979C14.4629 10.1979 13.9522 7.32963 14.2833 6.72103L10.0579 11.3872C11.091 10.529 14.218 10.515 14.218 10.515C14.3673 10.4824 14.4699 10.3494 14.4629 10.1979Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_15772_6371">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
}
